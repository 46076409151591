<template>
  <div class="main_sidebar-e">
    <div class="main_sidebar-e__section mb-5" v-if="!isEventPage">
      <div style="text-align: center" v-if="chanLoading">
        <c-spinner ml="2" color="orange" thickness="2px" />
      </div>

      <div v-else>
        <c-text fontWeight="700" fontSize="15px">Recent Channels</c-text>
        <div v-if="!hasNoChannels">
          <c-box v-for="item in channels" :key="item.id">
            <channel-card
              :channel="item"
              @openChanelDrawer="openChanelDrawer"
            />
          </c-box>

          <div class="see_more">
            <c-button
              size="sm"
              variant="link"
              variant-color="grey"
              @click="showChannelList = true"
            >
              See More
            </c-button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_sidebar-e__section">
      <div class="c_event">
        <i class="fas fa-users"></i>
        <div>
          <p>Create a channel and invite your network to join</p>
          <c-button
            @click="openCreateChannel"
            size="sm"
            variant-color="vc-orange"
            variant="outline"
            mt="2"
            class="hover_btn"
          >
            Create a Channel
          </c-button>
        </div>
      </div>
    </div>
    <br />
    <div class="main_sidebar-e__section">
      <CreateEventCard @create-event="isEventModalOpen = true" />
    </div>
    <br />
    <div v-if="isEventPage && eventId">
      <div class="main_sidebar-e__section" v-if="getAttendeesMatching.length">
        <c-text fontWeight="700" fontSize="20" class="title"
          >Attending Event</c-text
        >

        <div style="text-align: center" v-if="attendeesLoading">
          <c-spinner ml="2" color="orange" thickness="2px" />
        </div>

        <div v-else>
          <c-box v-for="item in getAttendeesMatching" :key="item.id">
            <person-card :person="item.attendee" />
          </c-box>
        </div>
      </div>
      <br />
    </div>

    <div class="main_sidebar-e__section mb-5" v-if="!hasNoAttendingEvents">
      <c-text fontWeight="700" fontSize="15px">Attending Events</c-text>
      <div style="text-align: center" v-if="isFetchingEvents">
        <c-spinner ml="2" color="orange" thickness="2px" />
      </div>
      <div v-else>
        <div v-if="!hasNoAttendingEvents">
          <c-box v-for="item in eventAttending" :key="item.id">
            <event-card
              :event="item"
            />
          </c-box>
        </div>
      </div>
    </div>
    <div class="main_sidebar-e__section" v-if="!hasNoEvents">
      <div>
        <c-text fontWeight="700" fontSize="16px">Upcoming Events</c-text>
        <div style="text-align: center" v-if="isFetchingEvents">
          <c-spinner ml="2" color="orange" thickness="2px" />
        </div>
        <div v-else>
          <c-box v-for="item in events" :key="item.id">
            <event-card :event="item" />
          </c-box>
          <div class="see_more" v-if="!isEventPage">
            <c-button
              size="sm"
              variant="link"
              variant-color="grey"
              @click="$router.push('/app/events')"
            >
              See All
            </c-button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="main_sidebar-e__section" v-if="!isEventPage">
      <c-text fontWeight="700" fontSize="15px" class="title"
        >Popular People</c-text
      >

      <div style="text-align: center" v-if="loading">
        <c-spinner ml="2" color="orange" thickness="2px" />
      </div>

      <div v-else>
        <c-box v-for="item in popularUsers" :key="item.id">
          <person-card :person="item" />
        </c-box>
      </div>
      <div class="see_more">
        <c-button
          size="sm"
          variant="link"
          variant-color="grey"
          @click="showMoreUsers = true"
        >
          See All
        </c-button>
      </div>
    </div>
    <br />
    <div class="main_sidebar-e__section" v-if="!isEventPage">
      <c-text fontWeight="700" fontSize="15px" class="title">
        Companies to follow</c-text
      >

      <div style="text-align: center" v-if="companiesLoading">
        <c-spinner ml="2" color="orange" thickness="2px" />
      </div>

      <div v-else>
        <c-box v-for="item in popularCompanies" :key="item.id">
          <company-card :company="item" />
        </c-box>
      </div>
      <!-- <div class="see_more">
        <c-button
          size="sm"
          variant="link"
          variant-color="grey"
          @click="showMoreCompanies = true"
        >
          See All
        </c-button>
      </div> -->
    </div>

    <ShowAllUsersModal
      v-if="showMoreUsers"
      @closeUerListModal="showMoreUsers = false"
    />

    <ChannelLists @close="showChannelList = false" v-if="showChannelList" />

    <ChannelDetailsDrawer
      :user="selectedChannel"
      :isOpen="isUserDrawerOpen"
      @close="closeChannelDrawer"
      v-if="isUserDrawerOpen"
    />
  </div>
</template>

<script>
import store from '@/store';
import ChannelCard from './components/sidebar/ChannelCard.vue';
import EventCard from '@/views/App/Explore/components/sidebar/EventCard.vue';
import PersonCard from '@/views/App/Explore/components/sidebar/PersonCard.vue';
import CompanyCard from '@/views/App/Explore/components/sidebar/CompanyCard.vue';
import ChannelLists from '@/views/App/Messaging/components/ChannelLists.vue';
import ChannelDetailsDrawer from '@/views/App/Messaging/components/ChannelDetailsDrawer.vue';
import { getPopularUserSuggestion } from '@/services/user.js';
import ShowAllUsersModal from './components/ShowAllUsersModal.vue';
import CreateEventCard from '@/views/App/Explore/components/sidebar/CreateEventCard.vue';
import { getOpenChannels } from '@/services/messages.js';
import { getPopularCompanyToFollow } from '@/services/company.js';
import {
  getEvents,
  getEventsAttendedByUser,
  getEventAttendees,
} from '@/services/event';

export default {
  name: 'Sidebar',
  props: {
    eventId: {
      type: Number || null || String,
      default: null,
    },
  },
  components: {
    ChannelCard,
    EventCard,
    PersonCard,
    ShowAllUsersModal,
    CreateEventCard,
    CompanyCard,
    ChannelLists,
    ChannelDetailsDrawer,
  },
  data() {
    return {
      isUserDrawerOpen: false,
      selectedChannel: null,
      isFetchingEvents: true,
      showChannelList: false,
      showMoreCompanies: false,
      companiesLoading: true,
      showMoreEvents: false,
      loading: true,
      chanLoading: true,
      attendeesLoading: true,
      showMoreUsers: false,
      channels: [],
      events: [],
      eventAttending: [],
      popularUsers: [],
      popularCompanies: [],
      attendees: [],
      isEventModalOpen: false,
      query: '',
    };
  },
  created() {
    this.getEvents();
    this.getEventsAttended();
    this.getPopularUsers();
    this.openChannelList();
    this.getCompanySuggestions();
    this.getEventAttendees();
  },
  computed: {
    getAttendeesMatching() {
      return this.attendees.filter((attn) => {
        return (
          attn.attendee.firstname.toLowerCase().includes(this.query) ||
          attn.attendee.lastname.toLowerCase().includes(this.query)
        );
      });
    },
    hasNoEvents() {
      return this.events.length === 0;
    },
    hasNoAttendingEvents() {
      return this.eventAttending.length === 0;
    },
    hasNoChannels() {
      return this.channels.length === 0;
    },
    isEventPage() {
      return (
        this.$route.name === 'Events' || this.$route.name === 'EventDetails'
      );
    },
  },
  methods: {
    openChanelDrawer(item) {
      this.selectedChannel = item;
      this.isUserDrawerOpen = true;
    },
    getEvents() {
      this.isFetchingEvents = true;
      getEvents({
        limit: 5,
        offset: 0,
      }).then((res) => {
        this.events = res.data.event;
        this.isFetchingEvents = false;
      });
    },
    getEventsAttended() {
      this.isFetchingEvents = true;
      const loggedInUserId = store.state.auth.user.id;
      getEventsAttendedByUser(loggedInUserId).then((res) => {
        this.eventAttending = res.data.event;
        this.isFetchingEvents = false;
      });
    },
    getEventAttendees() {
      getEventAttendees({ eventId: this.eventId, limit: 5 })
        .then((res) => {
          this.attendeesLoading = false;
          this.attendees = res.data.event_attendee;
        })
        .catch((e) => {
          this.attendeesLoading = false;
          console.log(e);
        });
    },
    async getCompanySuggestions() {
      const loggedInUser = store.state.auth.user.id;
      try {
        const response = await getPopularCompanyToFollow(loggedInUser, 7);
        const companies = response.data.company;
        this.popularCompanies = companies;
        this.companiesLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async openChannelList() {
      const loggedInUser = store.state.auth.user.id;
      try {
        const response = await getOpenChannels(loggedInUser, 5);
        const channels = response.data.chat_channel;

        // if has no channels
        if (channels.length > 0) {
          // add isChannel = true to all the channels data
          const formatChannel = channels.map((ch) => {
            return {
              ...ch,
              isChannel: true,
            };
          });

          this.channels = formatChannel;
        } else {
          this.channels = [];
        }

        this.chanLoading = false;
      } catch (error) {
        this.channels = [];
        this.chanLoading = false;
        console.log(error);
      }
    },

    openCreateChannel() {
      this.$router.push({ name: 'CreateNewChannel' });
    },
    async getPopularUsers() {
      const loggedInUser = store.state.auth.user.id;
      try {
        const response = await getPopularUserSuggestion(loggedInUser, 8);

        const users = response.data.user;

        this.popularUsers = users;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    closeChannelDrawer(type) {
      if (type === 'deleted') {
        this.isUserDrawerOpen = false;
        this.openChannelList();
      } else {
        this.isUserDrawerOpen = false;
      }
    },
  },
};
</script>

<style lang="scss">
.main_sidebar-e {
  max-width: 350px;

  &__section {
    padding: 15px 20px;
    border: 1px solid #dae7ec;
    box-shadow: 0 3px 6px rgba(186, 186, 186, 0.16);
    border-radius: 8px;
    background-color: white;

    .see_more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;
      background-color: #e2e8f4;
      font-weight: 600;
      font-size: 16px;
      padding: 10px;
    }
  }
}

.c_event {
  display: flex;
  justify-content: space-between;

  svg {
    font-size: 3.3rem;
    color: #d7def1;
  }

  div {
    margin-left: 20px;
  }

  p {
    font-size: 0.9rem;
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 5px;
  }
}
</style>
