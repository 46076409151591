<template>
  <div>
    <div class="c_event">
      <i class="far fa-calendar-alt"></i>
      <div>
        <p>Host an event and invite your network and peers</p>
        <c-button
          @click="openCreateEventModal"
          size="sm"
          variant-color="vc-orange"
          variant="outline"
          class="hover_btn"
        >
          Create an Event
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus';
export default {
  name: 'CreateEventCard',
  methods: {
    openCreateEventModal() {
      EventBus.$emit('open-modal');
      console.log('Show Modal here');
    },
  },
};
</script>
