<template>
  <c-flex my="6" justify="space-between" align="center">
    <c-flex align="center" @click="openDrawer" style="cursor: pointer">
      <!-- <c-image
        w="45px"
        rounded="lg"
        h="45px"
        :src="channel.url"
        alt="user-avatar"
      /> -->
      <span class="first_letter">{{ getFirstLetter(channel.name) }}</span>
      <c-box ml="2" color="gray.700">
        <c-text flex="column" fontSize="15px" fontWeight="600">{{
          channel.name
        }}</c-text>
        <c-text fontSize="13px" fontWeight="500"
          >{{ channel.participants.length }} Participants</c-text
        >
      </c-box>
    </c-flex>

    <c-flex justify="end">
      <c-button
        variant-color="vc-orange"
        borderRadius="lg"
        size="sm"
        border="1px"
        class="hover_btn"
        @click="joinChannel"
        :is-loading="btnLoading"
      >
        {{ followed ? 'Joined' : 'Join' }}
      </c-button>
      <!-- <span> <i class="far fa-check-square"></i></span> -->
    </c-flex>
  </c-flex>
</template>

<script>
import store from '@/store';
import { joinAChannel } from '@/services/messages.js';

export default {
  name: 'ChannelCard',
  props: ['channel'],
  data() {
    return {
      btnLoading: false,
      followed: false,
    };
  },
  methods: {
    openDrawer() {
      this.$emit('openChanelDrawer', this.channel);
    },
    getFirstLetter(name) {
      return name.charAt(0).toUpperCase();
    },
    async joinChannel() {
      this.btnLoading = true;
      const loggedInUser = store.state.auth.user.id;
      const payload = {
        channel_id: this.channel.id,
        participant_id: loggedInUser,
      };

      try {
        await joinAChannel(payload);
        const audio = new Audio(require('@/assets/sounds/tone.mp3'));
        audio.play();
        this.btnLoading = false;
        this.followed = true;
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.first_letter {
  font-size: 20px;
  font-weight: 600;
  background-color: #a4be85;
  min-width: 50px;
  min-height: 45px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

svg {
  color: var(--color-secondary);
  font-size: 20px;
}
button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
