<template>
  <c-modal
    :is-open="showChannelList"
    :on-close="closeChannelList"
    is-centered
    size="lg"
    style="border-radius: 10px"
  >
    <c-modal-content ref="content">
      <c-modal-header>Channel Lists</c-modal-header>
      <c-modal-close-button />
      <c-modal-body>
        <div class="search_input">
          <c-input-group>
            <c-input-left-element>
              <i class="fas fa-search"></i>
            </c-input-left-element>
            <c-input
              placeholder="Search channels..."
              borderWidth="0"
              borderRadius="20"
              size="lg"
              variant="filled"
              type="text"
              v-model="searchValue"
            />
          </c-input-group>
        </div>

        <c-box mt="5">
          <div style="text-align: center" v-if="loading">
            <c-spinner ml="2" color="orange" thickness="2px" />
          </div>

          <div v-else>
            <div v-for="(ch, index) in searchChannels" :key="index">
              <c-box
                d="flex"
                alignItems="center"
                justify-content="space-between"
                class="user_container"
              >
                <div class="user">
                  <span class="first_letter">{{
                    getFirstLetter(ch.name)
                  }}</span>
                  <div class="details">
                    <h3>{{ ch.name }}</h3>
                    <small
                      ><c-badge mx="2" variant-color="green">{{
                        ch.participants.length
                      }}</c-badge
                      >participants</small
                    >
                  </div>
                </div>

                <c-button
                  size="sm"
                  variant-color="vc-orange"
                  variant="outline"
                  @click="joinChannel(ch)"
                  :is-loading="btnLoading"
                >
                  {{ followed ? 'Joined' : 'Join' }}
                </c-button>
              </c-box>
            </div>
          </div>
        </c-box>
      </c-modal-body>
      <c-modal-footer
        d="flex"
        alignItems="center"
        justify-content="space-between"
      >
        <c-button @click="$emit('close')">Cancel</c-button>
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import store from '@/store';
import { joinAChannel, getOpenChannels } from '@/services/messages.js';

export default {
  name: 'ChannelLists',

  data() {
    return {
      searchValue: '',
      showChannelList: true,
      btnLoading: false,
      loading: true,
      followed: false,
      channels: [],
    };
  },
  computed: {
    searchChannels() {
      if (this.searchValue) {
        return this.channels.filter((data) => {
          return Object.keys(data).some((key) => {
            return String(data[key])
              .toLowerCase()
              .includes(this.searchValue.toLowerCase());
          });
        });
      } else {
        return this.channels;
      }
    },
  },
  created() {
    this.openChannelList();
  },
  methods: {
    async openChannelList() {
      const loggedInUser = store.state.auth.user.id;
      try {
        const response = await getOpenChannels(loggedInUser);
        this.channels = response.data.chat_channel;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    getFirstLetter(name) {
      if (name) {
        return name.charAt(0).toUpperCase();
      }
    },
    closeChannelList() {
      this.$emit('close');
    },
    async joinChannel(chan) {
      this.btnLoading = true;
      const loggedInUser = store.state.auth.user.id;
      const payload = {
        channel_id: chan.id,
        participant_id: loggedInUser,
      };

      try {
        await joinAChannel(payload);
        const audio = new Audio(require('@/assets/sounds/tone.mp3'));
        audio.play();
        this.btnLoading = false;
        this.followed = true;
        this.$emit('close');
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user_container {
  padding-right: 10px;
  &:hover {
    background: #e4eaf5;
    border-radius: 3px;
  }
  .user {
    display: flex;

    margin-bottom: 3px;
    cursor: pointer;
    padding: 8px 5px;

    .first_letter {
      font-size: 20px;
      font-weight: 600;
      background-color: #e7592e;
      min-width: 45px;
      min-height: 40px;
      border-radius: 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .details {
      margin-left: 10px;
      h3 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-primary);
      }

      p {
        margin-top: 3px;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: var(--color-primary);
      }
    }
  }
}

button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
