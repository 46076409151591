<template>
  <div>
    <c-modal
      :is-open="showModal"
      :on-close="closeUserModal"
      is-centered
      size="lg"
      style="border-radius: 10px"
    >
      <c-modal-content ref="content">
        <c-modal-header>Popular users </c-modal-header>
        <c-modal-close-button @click="closeUserModal()" />
        <c-modal-body>
          <div class="search_input">
            <c-input-group>
              <c-input-left-element>
                <i class="fas fa-search"></i>
              </c-input-left-element>
              <c-input
                placeholder="Search people..."
                borderWidth="0"
                borderRadius="20"
                size="lg"
                variant="filled"
                type="text"
                v-model="searchValue"
              />
            </c-input-group>
          </div>
          <div style="text-align: center" v-if="loading">
            <c-spinner ml="2" color="orange" thickness="2px" />
          </div>
          <div v-else class="search_result">
            <c-box v-for="item in searchUsers" :key="item.id">
              <person-card :person="item" />
            </c-box>
          </div>
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import store from '@/store';
import { getPopularUserSuggestion } from '@/services/user.js';
import PersonCard from '@/views/App/Explore/components/sidebar/PersonCard.vue';

export default {
  components: {
    PersonCard,
  },

  data() {
    return {
      loading: true,
      showModal: true,
      popularUsers: [],
      searchValue: '',
    };
  },
  computed: {
    searchUsers() {
      if (this.searchValue) {
        return this.popularUsers.filter((data) => {
          return Object.keys(data).some((key) => {
            return String(data[key])
              .toLowerCase()
              .includes(this.searchValue.toLowerCase());
          });
        });
      } else {
        return this.popularUsers;
      }
    },
  },
  created() {
    this.getPopularUsers();
  },
  methods: {
    async getPopularUsers() {
      const loggedInUser = store.state.auth.user.id;
      try {
        const response = await getPopularUserSuggestion(loggedInUser, 100);

        const users = response.data.user;

        this.popularUsers = users;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    closeUserModal() {
      this.showModal = false;
      this.$emit('closeUerListModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.search_result {
  margin-top: 10px;
  height: 500px;
  overflow-y: scroll;
}
</style>
