import { render, staticRenderFns } from "./ChannelLists.vue?vue&type=template&id=7b581ff2&scoped=true"
import script from "./ChannelLists.vue?vue&type=script&lang=js"
export * from "./ChannelLists.vue?vue&type=script&lang=js"
import style0 from "./ChannelLists.vue?vue&type=style&index=0&id=7b581ff2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b581ff2",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CSpinner: require('@chakra-ui/vue').CSpinner, CBadge: require('@chakra-ui/vue').CBadge, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
